<template>
  <div class="storia-detail" :class="{ mobile: $vuetify.breakpoint.smAndDown }">
    <!-- page title -->
    <page-title></page-title>
    <back-button></back-button>

    <v-container class="storia-detail-body">
      <!-- page header -->
      <v-row>
        <v-col class="col-auto col-md-8">
          <h2 class="storia-title mb-4" v-html="titolo"></h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="col-auto col-md-4 d-flex justify-end">
          <share-button></share-button>
        </v-col>
      </v-row>
       <div class="container-img-testo mb-8 mt-8">
      <img
        :src="baseUrl + media"
        class="img-testo"
      />
      </div>

      <!-- paragrafi -->
      <v-row
        v-for="paragrafo in paragrafi"
        :key="paragrafo.id"
        class="pt-8 px-md-2 paragrafo"
      >
        <v-col v-if="paragrafo.tipo != 'TestoConWidget'" class="pb-0" cols="12">
          <p class="mb-0 contenuto" v-html="paragrafo.contenuto"></p>
        </v-col>
        <v-col class="pt-0" v-if="paragrafo.tipo == 'TestoConMedia'" cols="12">
            <div class="container-img-testo mb-8 mt-8">
            <img
            :src="baseUrl + paragrafo.url_relativa_img"
            class="img-testo"
              />
              </div>
        </v-col>
        <v-col v-if="paragrafo.tipo == 'TestoConWidget'">
          <yucca-widget
            :html="paragrafo.widget_html"
            :script="paragrafo.widget_script"
          ></yucca-widget>
        </v-col>
      </v-row>

      <!-- allegati -->
      <h3 class="mt-16 section-title" v-if="allegati.length > 0">Allegati</h3>
      <v-row class="allegati">
        <v-col
          v-for="allegato in allegati"
          :key="allegato.nome"
          :class="allegato.tipo == 'Image' ? 'col-6 col-sm-3' : 'col-12'"
        >
          <!-- document -->
          <div v-if="allegato.tipo == 'Document' || allegato.tipo == 'Other'">
            <div class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default my-4">
              <a :href="baseUrl + allegato.url"
                ><v-icon>file_copy</v-icon>{{ decode(allegato.nome) }}</a
              >
            </div>
          </div>
          <!-- image -->
          <div v-if="allegato.tipo == 'Image'" class="allegato container-img-testo-icon">
            <img
              :src="baseUrl + allegato.url"
              @click="downloadAllegato(baseUrl + allegato.url, allegato.nome)"
              class="allegato-img text-center img-testo align-center my-4"
              alt=" "
              /><v-icon class="download-icon">get_app</v-icon>
            
          </div>
          <!-- video -->
          <div v-if="allegato.tipo == 'Video'">
            <video class="video" controls :src="baseUrl + allegato.url"></video>
            <div class="text-center">
              <v-btn
                @click="downloadAllegato(baseUrl + allegato.url, allegato.nome)"
              >
                {{ allegato.nome }}<v-icon>get_app</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- remote video -->
          <div v-if="allegato.tipo == 'Remote Video'">
            <iframe
              class="video"
              :src="baseUrl + allegato.url"
              frameborder="0"
            ></iframe>
          </div>
          <!-- audio -->
          <div v-if="allegato.tipo == 'Audio'">
            <audio :src="baseUrl + allegato.url" controls></audio>
            <div>
              <v-btn
                @click="downloadAllegato(baseUrl + allegato.url, allegato.nome)"
              >
                {{ allegato.nome }}<v-icon>get_app</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- links -->
      <h3 class="mt-8 section-title" v-if="links.length > 0">Link</h3>
      <v-row>
        <v-col class="col-12 col-sm-auto" v-for="link in links" :key="link">
          <v-btn class="link-list"><div v-html="link"></div></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import BackButton from "../components/BackButton"
import ShareButton from "../components/ShareButton"
import YuccaWidget from "../components/YuccaWidget"
import { getStorieDetail } from "../services/api"
export default {
  name: "StorieDetail",
  components: {
    PageTitle,
    BackButton,
    ShareButton,
    YuccaWidget
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      storia: {},
    };
  },
  async created() {
    this.$emit("setLoading", true);

    let storiePromise = getStorieDetail(this.id);

    try {
      let { data } = await storiePromise;
      this.storia = data[0];
    } catch (err) {
      console.log(err);
    }

    this.$emit("setLoading", false);
  },
  computed: {
    id() {
      return this.$route.params.id ?? 0;
    },
    titolo() {
      return this.storia?.titolo ?? "";
    },
    media() {
      return this.storia?.url_relativa_img ?? "";
    },
    allegati() {
      return this.storia?.allegati ?? "";
    },
    paragrafi() {
      if (this.storia?.paragrafi != "") {
        let paragraph =
          this.storia?.paragrafi?.filter((r) => {
            return r.stato === "1";
          }) ?? [];
        return paragraph;
      } else {
        return "";
      }
    },
    links() {
      let links = this.storia?.link?.split(",") ?? [];
      return links != "" ? links : [];
    },
  },
  methods: {
    decode(value) {
      return unescape(value);
    },
    downloadAllegato(url, name) {
      var element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.storia-detail {
  a {
    color: #2ab5b9;
  }
  .storia-detail-body {
    .storia-title {
      font: normal normal bold 30px/40px Open Sans;
      letter-spacing: 0px;
      color: $text-dark;
    }
    .section-title {
      font: normal normal bold 27px/37px Open Sans;
      letter-spacing: 0px;
    }
    .paragrafo {
      text-align: justify;
    }
    .contenuto {
      font: normal normal normal 20px/30px Open Sans;
      letter-spacing: 0px;
    }
    .allegati {
      .allegato-img {
        cursor: pointer;
        .download-icon {
          color: $text-white;
        }
      }
      a {
        text-decoration: none;
        color: $text-dark;
      }
      .allegato {
        &:hover {
          transform: scale(1.2);
        }
      }
      .video {
        max-width: 500px;
      }
    }
    .video {
      max-width: 100%;
    }
  }
  .link-list {
    a {
      text-decoration: none;
      color: $text-dark;
    }
  }
}
.mobile {
  .paragrafo {
    text-align: inherit;
  }
}
</style>